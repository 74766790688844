import IDisk from 'src/ServerEntities/IDisk';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 8px;
  width: 500px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
`;

const BarSection = styled.div`
  display: flex;
  align-items: center;
`;

const BarBackground = styled.div`
  flex-grow: 1;
  background-color: #eee;
  height: 14px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  position: relative;
`;

const BarUsed = styled.div<{ width: number }>`
  width: ${(props) => props.width}%;
  background-color: #57606A;
  height: 100%;
`;

const BarFree = styled.div<{ width: number }>`
  width: ${(props) => props.width}%;
  background-color: #2DA44E;
  height: 100%;
`;

const JvmUsed = styled.div<{ width: number }>`
  width: ${(props) => props.width}%;
  background-color: #0465D9;
  height: 100%;
`;

const PercentageLabel = styled.div`
  margin-left: 15px;
  font-size: 16px;
  font-weight: bold;
`;

const InfoLabel = styled.div`
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
`;

const TitleLabel = styled.p`
  margin: 0 0 5px;
`;

interface IProps {
  diskUsage: IDisk;
};

const calculateUsedSize = (usedSize: number, jvmSize: number) => {
  let total = 0;
  if (!isNaN(usedSize)) {
    total += usedSize;
  }
  if (!isNaN(jvmSize)) {
    total += jvmSize;
  }
  return total;
};

const DiskUsage = (props: IProps) => {
  const { diskUsage } = props;
  const totalSize = parseFloat(diskUsage.sizeGb);
  const usedSize = parseFloat(diskUsage.usedGb);
  const freeSize = parseFloat(diskUsage.freeGb);
  const jvmSize = parseFloat(diskUsage.jvmSpace);

  const usedPercentage = ((usedSize / totalSize) * 100).toFixed(0);
  const freePercentage = ((freeSize / totalSize) * 100).toFixed(0);
  const jvmPercentage = ((jvmSize / totalSize) * 100).toFixed(0);

  return (
    <Container>
      <TitleLabel>Disk usage:</TitleLabel>
      <BarSection>
        <BarBackground> 
          <BarFree width={parseFloat(freePercentage)} />
          <JvmUsed width={parseFloat(jvmPercentage)} />
          <BarUsed width={parseFloat(usedPercentage)} />
        </BarBackground>
        <PercentageLabel>{freePercentage}%</PercentageLabel>
      </BarSection>
      <InfoLabel>
        Free: {freeSize}GB | Used: {calculateUsedSize(usedSize, jvmSize)}GB
      </InfoLabel>
    </Container>
  );
};

export default DiskUsage;

