import React from 'react';
import 'react-toggle/style.css';
import styled from 'styled-components';

import IRhapsodyDashboard from 'src/ServerEntities/IRhapsodyDashboard';
import IRhapsodyServer from 'src/ServerEntities/IRhapsodyServer';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import { SessionContext } from 'src/Views/SessionContext';
import { getRhapsodyDashboard, getRhapsodyHeader } from '../ramService';
import AlertsPanel from './AlertsPanel';
import ComponentPanel from './ComponentPanel';
import MessageThroughputPanel from './MessageThroughputPanel';
import QueuesPanel from './QueuesPanel';
import ServerDetailsPanel from './ServeDetailsPanel';


const SiteContainer = styled.ul`
    margin-top: 24px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    width: 1280px;
`;

export const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    @media (min-width: 1279px) {
        padding: 10px 48px 104px;
    }
    @media (max-width: 1279px) {
        margin-bottom: 120px;
    }
`;

interface IProps {
    selectedTypes: string[],
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};

const TieInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
`;

const TieTitle = styled.div`
    font-size: 25px;
    font-weight: bold;
`;

const VersionTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #1577AE;
`;

const LicenceTitle = styled.div`
    display: flex;
    flex-direction: column;
     font-size: 18px;
    font-weight: bold;
`;

const LicenceItem = styled.div`
`;

const ServerContainer = styled.div`
 display: flex;
    flex-direction: row;
`;

const ServerItem = styled.div`
    border-radius: 30px;
    margin-right: 20px;
    padding: 8px;
    background-color: #ccc;
    color: #FFF;
    width: 300px;
    text-align: center;
    font-size: 24px;
`;

const ServerItemSelected = styled.div`
    border-radius: 30px;
    margin-right: 20px;
    padding: 8px;
    background-color: #1577AE;
    color: #FFF;
    width: 300px;
    text-align: center;
    font-size: 24px;
`;

const LeftColumn = styled.div`
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    flex-direction: row;

`;

const RightColumn = styled.div`
       display: flex;
    width: 824px;
    gap: 15px;
    flex-wrap: wrap;
    flex-direction: row;
`;

const RhapsodyDashboard = (props: IProps) => {
    const { state, dispatch } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [data, setData] = React.useState(undefined as unknown as IRhapsodyServer[]);
    const [selectedServer, setSelectedServer] = React.useState(0);
    const [dashboard, setDashboard] = React.useState(undefined as unknown as IRhapsodyDashboard)
    const headerInfo = state.headerInfo ? state.headerInfo : data;

    React.useEffect(() => {
        if (!state.headerInfo) {
            setLoading(true);
            getRhapsodyHeader(state.webToken, (serverData: IRhapsodyServer[]) => {
                setLoading(false);
                setData(serverData);
                setError("");
                loadDashboard(data);
                dispatch({ type: "setHeaderInfo", payload: serverData });
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        } else {
            loadDashboard(headerInfo);
        }
    }, [selectedServer]);

    const loadDashboard = (header: IRhapsodyServer[]) => {
        setLoading(true);
        if (header[selectedServer]) {
            getRhapsodyDashboard(header[selectedServer].id, state.webToken, (data: IRhapsodyDashboard) => {
                setLoading(false);
                setDashboard(data);
                setError("");
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }

    const serverToTab = (server: IRhapsodyServer, index: number) => {
        const selectServer = (serverIndex: number) => () => {
            setSelectedServer(serverIndex);
        }
        if (selectedServer === index) {
            return <ServerItemSelected key={`rhapsody-server-${server.id}-dashboard`} onClick={selectServer(index)}>{server.server}</ServerItemSelected>
        }
        return <ServerItem key={`rhapsody-server-${server.id}-dashboard`} onClick={selectServer(index)}>{server.server}</ServerItem>
    }

    return <ContentContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        {headerInfo && headerInfo[selectedServer] && <TieInfo>
            <TieTitle>Rhapsody Integration Engine</TieTitle>
            <VersionTitle>version {headerInfo[selectedServer].licenceVersion}</VersionTitle>
            <LicenceTitle>
                <LicenceItem>Licence: {headerInfo[selectedServer].licenceEndUser}</LicenceItem>
                <LicenceItem>Expiry: {dateConverter(headerInfo[selectedServer].licenceExpiryDate)}</LicenceItem>
            </LicenceTitle>
        </TieInfo>}
        <ServerContainer>
            {headerInfo && headerInfo.map(serverToTab)}
        </ServerContainer>
        <SiteContainer>
            <LeftColumn>
                {dashboard && <ServerDetailsPanel data={dashboard.server} />}
            </LeftColumn>
            <RightColumn>
                {dashboard && <MessageThroughputPanel today={dashboard.todayMessageCounts} yesterday={dashboard.yesterdayMessageCounts} />}
                {dashboard && <ComponentPanel data={dashboard.components} />}
                {dashboard && <AlertsPanel data={dashboard.alerts} />}
                {dashboard && <QueuesPanel data={dashboard.queues} />}
            </RightColumn>
        </SiteContainer>
    </ContentContainer>;
};

export default RhapsodyDashboard;
