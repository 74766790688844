import 'react-toggle/style.css';
import styled from 'styled-components';


import IRhapsodyComponent from 'src/ServerEntities/IRhapsodyComponent';
import IRhapsodyComponents from 'src/ServerEntities/IRhapsodyComponents';


const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`;


const OuterContainer = styled.div`
    width: 350px;
    border-radius: 25px;
    border: 5px solid #44A2D6;
    padding: 2px;
    height: 100%;
`;


const InnerContainer = styled.div`
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
`;

const TitleBar = styled.div`
    width: 340px;
    border-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    color: #FFF;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
`;

const StateBubble = styled.div`
    border-radius: 15px;
    padding: 1px;
    padding-bottom: 2px;
    min-width: 70px;
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    &.ERROR  {
        background-color: #F00;
        color: #FFF;
    }
    &.STOPPED  {
        background-color: #B7BEC2;
        color: #000;
    }
    &.RUNNING  {
        background-color: #5CEA5A;
        color: #FFF;
    }
     &.RETRYING  {
        background-color: #2DF6C0;
        color: #FFF;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5px
`;

const ComponentContainer = styled.div`
   margin-top: 5px;
   margin-bottom: 5px
`;

const ComponentHeader = styled.div`
   display: flex;
 flex-direction: row;
 margin-bottom: 5px;
 font-weight: bold;
 justify-content: space-evenly
`;

const BarItem = styled.div`
    &.ERROR  {
        color: #F00;
    }
    &.STOPPED  {
        color: #B7BEC2;
    }
    &.RUNNING  {
        color: #5CEA5A;
    }
    &.RETRYING  {
        color: #2DF6C0;
    }
`;

interface IProps {
    data: IRhapsodyComponents;
};

const renderState = (state: string) => {
    switch (state) {
        case "ERROR": return <StateBubble className={state}>Errored</StateBubble>
        case "STOPPED": return <StateBubble className={state}>Stopped</StateBubble>
        case "RUNNING": return <StateBubble className={state}>Running</StateBubble>
        case "RETRYING": return <StateBubble className={state}>Retrying</StateBubble>
    }
    return null;
}

const ComponentPanel = (props: IProps) => {
    const { data } = props;

    const renderComponent = (name: string, components: IRhapsodyComponent[]) => {
        const total = components.length;
        const errorCount = components.filter(c => c.state === "ERROR").length;
        const retryingCount = components.filter(c => c.state === "RETRYING").length;
        const stoppedCount = components.filter(c => c.state === "STOPPED").length;
        const runningCount = components.filter(c => c.state === "RUNNING").length;
        const errorWidth = (errorCount / total) * 100;
        const retryingWidth = (retryingCount / total) * 100;
        const stoppedWidth = (stoppedCount / total) * 100;
        const runningWidth = (runningCount / total) * 100;

        return (
            <ComponentContainer>
                <ComponentHeader>
                    <div style={{ width: "50%" }}>{name}</div>
                    <div style={{ width: "10%" }}>{total}</div>
                    <BarItem className='ERROR' style={{ width: "10%" }}>{errorCount}</BarItem>
                    <BarItem className='STOPPED' style={{ width: "10%" }}>{stoppedCount}</BarItem>
                    <BarItem className='RETRYING' style={{ width: "10%" }}>{stoppedCount}</BarItem>
                    <BarItem className='RUNNING' style={{ width: "10%" }}>{runningCount}</BarItem>
                </ComponentHeader>

                <div style={{ display: 'flex', width: '100%', height: '20px', border: '0px solid black', borderRadius: '10px', overflow: 'hidden' }}>
                    <div style={{ width: `${errorWidth}%`, backgroundColor: 'red', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}></div>
                    <div style={{ width: `${retryingWidth}%`, backgroundColor: '#2DF6C0' }}></div>
                    <div style={{ width: `${stoppedWidth}%`, backgroundColor: '#B7BEC2' }}></div>
                    <div style={{ width: `${runningWidth}%`, backgroundColor: '#5CEA5A', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}></div>
                </div>
            </ComponentContainer >
        );
    };



    return <ContentContainer>
        <OuterContainer>
            <TitleBar>Components</TitleBar>
            <InnerContainer>
                {renderComponent("Routes", data.routes)}
                {renderComponent("Comm Points", data.commpoints)}
                {renderComponent("Web Services", data.webServices)}
            </InnerContainer>
            <Footer>
                {renderState("ERROR")}
                {renderState("RETRYING")}
                {renderState("STOPPED")}
                {renderState("RUNNING")}
            </Footer>
        </OuterContainer>
    </ContentContainer>;
};

export default ComponentPanel;
