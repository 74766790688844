import React from 'react';
import 'react-toggle/style.css';
import styled from 'styled-components';

import IRhapsodyComponents from 'src/ServerEntities/IRhapsodyComponents';
import IRhapsodyServer from 'src/ServerEntities/IRhapsodyServer';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import { SessionContext } from 'src/Views/SessionContext';
import { getRhapsodyComponents, getRhapsodyHeader } from '../ramService';
import { ContentContainer } from './Dashboard';
import RhapsodyCommpoints from './RhapsodyCommpoints';
import RhapsodyRoutes from './RhapsodyRoutes';
import RhapsodyWebServices from './RhapsodyWebServices';


const SiteContainer = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    margin-top: 20px;
`;

interface IProps {
    selectedTypes: string[],
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};

const TieInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
`;

const TieTitle = styled.div`
    font-size: 1.3em;
    font-weight: bold;
`;

const VersionTitle = styled.div`
    font-size: 1.1em;
    font-weight: bold;
    color: #1577AE;
`;

const LicenceTitle = styled.div`
    display: flex;
    flex-direction: column;
     font-size: 1.1em;
    font-weight: bold;
`;

const LicenceItem = styled.div`
`;

const ServerContainer = styled.div`
 display: flex;
    flex-direction: row;
`;

const ServerItem = styled.div`
    border-radius: 30px;
    margin-right: 20px;
    padding: 8px;
    background-color: #ccc;
    color: #FFF;
    width: 300px;
    text-align: center;
    font-size: 24px;
`;

const ServerItemSelected = styled.div`
    border-radius: 30px;
    margin-right: 20px;
    padding: 8px;
    background-color: #1577AE;
    color: #FFF;
    width: 300px;
    text-align: center;
    font-size: 24px;
`;

const RhapsodyComponents = (props: IProps) => {
    const { state, dispatch } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [data, setData] = React.useState(undefined as unknown as IRhapsodyServer[]);
    const [components, setComponents] = React.useState(undefined as unknown as IRhapsodyComponents);
    const [selectedServer, setSelectedServer] = React.useState(0);
    const headerInfo = state.headerInfo ? state.headerInfo : data;


    React.useEffect(() => {
        if (!state.headerInfo) {
            setLoading(true);
            getRhapsodyHeader(state.webToken, (serverData: IRhapsodyServer[]) => {
                setLoading(false);
                setData(serverData);
                setError("");
                loadComponents(serverData);
                dispatch({ type: "setHeaderInfo", payload: serverData });
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        } else {
            loadComponents(headerInfo);
        }
    }, [selectedServer]);

    const loadComponents = (header: IRhapsodyServer[]) => {
        setLoading(true);
        if (header[selectedServer]) {
            getRhapsodyComponents(header[selectedServer].id, state.webToken, (data: IRhapsodyComponents) => {
                setLoading(false);
                setComponents(data);
                setError("");
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }

    const serverToTab = (server: IRhapsodyServer, index: number) => {

        const selectServer = (serverIndex: number) => () => {
            setSelectedServer(serverIndex);
        }

        if (selectedServer === index) {
            return <ServerItemSelected key={`rhapsody-server-${server.id}`} onClick={selectServer(index)}>{server.server}</ServerItemSelected>
        }
        return <ServerItem key={`rhapsody-server-${server.id}`} onClick={selectServer(index)}>{server.server}</ServerItem>
    };

    return <ContentContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        {headerInfo && <TieInfo>
            <TieTitle>Rhapsody Integration Engine</TieTitle>
            <VersionTitle>version {headerInfo[selectedServer].licenceVersion}</VersionTitle>
            <LicenceTitle>
                <LicenceItem>Licence: {headerInfo[selectedServer].licenceEndUser}</LicenceItem>
                <LicenceItem>Expiry: {dateConverter(headerInfo[selectedServer].licenceExpiryDate)}</LicenceItem>
            </LicenceTitle>
        </TieInfo>}
        <ServerContainer>
            {headerInfo && headerInfo.map(serverToTab)}
        </ServerContainer>
        <SiteContainer>
            {components && components.routes && <RhapsodyRoutes data={components.routes} />}
            {components && components.commpoints && <RhapsodyCommpoints data={components.commpoints} />}
            {components && components.webServices && <RhapsodyWebServices data={components.webServices} />}
        </SiteContainer>
    </ContentContainer>;
};

export default RhapsodyComponents;
