import React from 'react';
import Timeago from 'react-timeago';
import 'react-toggle/style.css';
import styled from 'styled-components';

import IRhapsodyAlert from 'src/ServerEntities/IRhapsodyAlert';

interface IProps {
    alert: IRhapsodyAlert
};

const AlertContainer = styled.li`
    margin-bottom: 10px;
    border-radius: 20px;
    background-color: #1C3E5A;
    color: #FFF;
    width: 100%;
`;

const AlertExpandedContainer = styled.li`
    margin-bottom: 10px;
    background-color: #1577AE;
    padding: 15px;
    color: #FFF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`;

const AlertLine = styled.li`
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


const TimeContainer = styled.div`
   width: 170px;
    text-align: center;
`;

const CountContainer = styled.div`
   width: 50px;
   height: 32px;
   background-color: #F99601;
   border-radius: 20px;
   color: #000000;
   text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
`;

const SeverityContainer = styled.div`
   width: 100px;
    text-align: right;
`;

const AlertNameContainer = styled.div`
   width: 100%;
   padding-left: 20px;
`;

const RhapsodyAlertItem = (props: IProps) => {
    const [expanded, setExpanded] = React.useState(false);
    const { alert } = props;
    const onClick = () => {
        setExpanded(!expanded);
    }
    return <div>
        <AlertContainer>
            <AlertLine onClick={onClick}>
                <TimeContainer><Timeago date={alert.timestamp} /></TimeContainer>
                <CountContainer>{alert.count}</CountContainer>
                <AlertNameContainer>{alert.alertName}</AlertNameContainer>
                <SeverityContainer>{alert.severity}</SeverityContainer>
            </AlertLine>
            {expanded && <AlertExpandedContainer>
                <div>{alert.alertDescription}</div>
            </AlertExpandedContainer>}
        </AlertContainer >
    </div >


};

export default RhapsodyAlertItem;
