import 'react-toggle/style.css';
import IRhapsodyServerPanel from 'src/ServerEntities/IRhapsodyServerPanel';
import styled from 'styled-components';

import Timeago from 'react-timeago';

import { DateTime } from 'luxon';
import { HEARTBEAT_WARNING_LIMIT } from 'src/config';
import heartbeatBadIcon from 'src/images/customIcons/heartbeat-bad.png';
import heartbeatGoodIcon from 'src/images/customIcons/heartbeat-good.png';
import IDisk from 'src/ServerEntities/IDisk';
import IRhapsodyMetric from 'src/ServerEntities/IRhapsodyMetric';

interface IProps {
    data: IRhapsodyServerPanel;
};


const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    margin-right: 15px
`;


const OuterContainer = styled.div`
    width: 350px;
    border-radius: 25px;
    border: 5px solid #44A2D6;
    padding: 2px;
    min-height: 550px;
`;


const InnerContainer = styled.div`
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
`;

const TitleBar = styled.div`
    width: 340px;
    border-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    color: #FFF;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
`;



const ComponentContainer = styled.div`
   margin-top: 5px;
   margin-bottom: 15px
   padding-right: 10px;
`;

const ComponentHeader = styled.div`
margin-top: 10px;
display: flex;
 flex-direction: row;
 margin-bottom: 5px;
 font-weight: bold;
 justify-content: space-between;
 margin-left: 12px;
 margin-right: 12px;
 font-weight: bold;
`;

const ComponentBar = styled.div`
    display: flex;
    width: 100%; 
    height: 40px;
    border: 0px solid black;
    border-radius: 20px;
    overflow: hidden;
`;

const ComponentName = styled.div`

`;

const ComponentAmount = styled.div`

`;

const HearbeatBar = styled.div`
    width: 100%;
    height:40px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
`;

const UptimeBar = styled.div`
    height:40px;
    border-radius: 30px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: 3px solid #1577AE;
`;

const ServerDetailsPanel = (props: IProps) => {
    const { data } = props;
    const heartbeatHealthy = data && data.lastHeartbeat > (DateTime.now().toMillis() - HEARTBEAT_WARNING_LIMIT);

    const renderBar = (name: string, percentage: number) => {
        const redWidth = percentage;
        const blueWidth = 100 - percentage;

        return (
            <ComponentContainer>
                <ComponentHeader>
                    <ComponentName>{name}</ComponentName>
                    <ComponentAmount>{percentage}%</ComponentAmount>
                </ComponentHeader>
                <ComponentBar>
                    <div style={{ width: `${redWidth}%`, backgroundColor: 'red', borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px' }}></div>
                    <div style={{ width: `${blueWidth}%`, backgroundColor: '#1577AE' }}></div>
                </ComponentBar>
            </ComponentContainer >
        );
    };

    const calculateMemoryUsed = (memory: IRhapsodyMetric) => {
        return Math.floor(((memory.inUse + memory.jvmInUse + memory.working) / memory.totalAllocated) * 100);
    }

    const calculateWSThreadsUsed = (wsThreads: IRhapsodyMetric) => {
        return Math.floor((wsThreads.currentActiveThreadCount / wsThreads.maxTotalConnections) * 100);
    }

    const formatTime = (time: number) => {
        if (time < 60) {
            return `${time} seconds`;
        } else if (time < 3600) {
            const minutesAgo = Math.floor(time / 60);
            return `${minutesAgo} minutes`;
        } else if (time < 86400) {
            const hoursAgo = Math.floor(time / 3600);
            return `${hoursAgo} hours`;
        } else {
            const daysAgo = Math.floor(time / 86400);
            return `${daysAgo} days`;
        }
    };

    const renderDiskSpaceMetrics = (metrics: IDisk) => {
        const percentFree: number = parseFloat(metrics.percentFree.replace("%", ""));
        return renderBar("Disk Usage Drive " + metrics.diskId, 100 - percentFree)
    }

    return (
        <ContentContainer>
            <OuterContainer>
                <TitleBar>Server Details</TitleBar>
                <InnerContainer>
                    <ComponentHeader>
                        <ComponentName>Last heartbeat</ComponentName>
                    </ComponentHeader>
                    <ComponentBar>
                        <HearbeatBar style={{ backgroundColor: heartbeatHealthy ? '#5CEA5A' : '#FF0000' }}>
                            <div style={{ width: "32px" }}></div>
                            <Timeago date={data.lastHeartbeat} />
                            <img height={28} width={28} src={heartbeatHealthy ? heartbeatGoodIcon : heartbeatBadIcon} />
                        </HearbeatBar>
                    </ComponentBar>
                    <ComponentHeader>
                        <ComponentName>Uptime</ComponentName>
                    </ComponentHeader>
                    <UptimeBar>
                        {data.lastBoot ? formatTime(data.lastBoot) : "Not known"}
                    </UptimeBar>
                    <div>
                        {data.spaceMetrics.map((diskMetric) => (
                            <div key={diskMetric.computerName}>
                                {renderDiskSpaceMetrics(diskMetric)}
                            </div>
                        ))}
                    </div>
                    {renderBar("CPU", data.cpu.percent)}
                    {renderBar("Memory", calculateMemoryUsed(data.memory))}
                    {renderBar("WS Threads", calculateWSThreadsUsed(data.wsThreads))}
                </InnerContainer>
            </OuterContainer>
        </ContentContainer>
    );
};


export default ServerDetailsPanel;
