import 'react-toggle/style.css';
import styled from 'styled-components';


import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

import React from 'react';
import IRhapsodyQueue from 'src/ServerEntities/IRhapsodyQueue';
import IRhapsodyQueues from 'src/ServerEntities/IRhapsodyQueues';

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`;

const TimeWindowButton = styled.div`
      border-radius: 10px;
    margin-right: 20px;
    padding: 5px;
    background-color: #ccc;
    color: #FFF;
    width: 100px;
    text-align: center;
    font-size: 14px;
    &.selected {
        background-color: #1577AE;
    }
`;

const TimeWindowContainer = styled.div`
    display: flex;
`;

interface IProps {
    queues: IRhapsodyQueues
};

const TYPE_HOLD = "HOLD";
const TYPE_ERROR = "ERROR";
const TODAY = 0;
const YESTERDAY = 1;
const TWODAYS = 2;
const FIVEDAYS = 5;
const SEVENDAYS = 7;

const QueueSizeChart = (props: IProps) => {
    const now = new Date();
    const [timeWindow, setTimeWindow] = React.useState(TODAY);
    const [holdQueue, setHoldQueue] = React.useState(undefined as unknown as IRhapsodyQueue[]);
    const [errorQueue, setErrorQueue] = React.useState(undefined as unknown as IRhapsodyQueue[]);

    const getCategoriesForTimeWindow = (timeWindow: number): string[] => {
        const categories = [];
        const startHour = 0;
        const endHour = 23;

        for (let days = timeWindow; days >= 0; days--) {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - days);
            const formattedDate = currentDate.toISOString().split("T")[0];

            for (let hour = startHour; hour <= endHour; hour++) {
                categories.push(`${formattedDate} ${hour}:00`);
            }
        }

        return categories;
    };

    const filterByDay = (daysAgo: number, type: string): IRhapsodyQueue[] => {
        const startOfDay = new Date(now);
        startOfDay.setDate(now.getDate() - daysAgo);
        startOfDay.setHours(0, 0, 0, 0);

        const endOfDay = daysAgo === 1 ? new Date(startOfDay) : new Date(now);
        endOfDay.setHours(23, 59, 59, 999);

        const queues = type === "ERROR" ? props.queues.errorQueue : props.queues.holdQueue;
        return queues.filter((queue) => {
            const queueDate = new Date(queue.timestamp);
            return queueDate >= startOfDay && queueDate <= endOfDay;
        });
    };

    React.useEffect(() => {
        if (props.queues) {
            setHoldQueue(filterByDay(timeWindow, TYPE_HOLD));
            setErrorQueue(filterByDay(timeWindow, TYPE_ERROR));
        }
    }, [timeWindow, props.queues]);

    const options: ApexOptions = {
        chart: {
            type: 'line',
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [5, 5],
            curve: 'smooth',
            dashArray: [0, 0],
        },
        legend: {
            tooltipHoverFormatter: function (val, opts) {
                return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>';
            },
        },
        colors: ['#FF0000', '#1577AE', '#2DF6C0', '#5CEA5A', '#B7BEC2'],
        markers: {
            size: 0,
            hover: {
                sizeOffset: 10,
            },
        },
        xaxis: {
            categories: getCategoriesForTimeWindow(timeWindow),
        },
        grid: {
            borderColor: '#f1f1f1',
        },
    };

    const series = [
        {
            name: "Error Queue",
            data: errorQueue ? errorQueue.map((q) => q.queue) : [],
        },
        {
            name: "Hold Queue",
            data: holdQueue ? holdQueue.map((q) => q.queue) : [],
        },
    ];

    const onClickTimeWindow = (time: number) => () => {
        setTimeWindow(time);
    };



    return (
        <ContentContainer>
            <TimeWindowContainer>
                <TimeWindowButton className={timeWindow === TODAY ? "selected" : ""} onClick={onClickTimeWindow(TODAY)}>Today</TimeWindowButton>
                <TimeWindowButton className={timeWindow === YESTERDAY ? "selected" : ""} onClick={onClickTimeWindow(YESTERDAY)}>Yesterday</TimeWindowButton>
                <TimeWindowButton className={timeWindow === TWODAYS ? "selected" : ""} onClick={onClickTimeWindow(TWODAYS)}>2 Days</TimeWindowButton>
                <TimeWindowButton className={timeWindow === FIVEDAYS ? "selected" : ""} onClick={onClickTimeWindow(FIVEDAYS)}>5 Days</TimeWindowButton>
                <TimeWindowButton className={timeWindow === SEVENDAYS ? "selected" : ""} onClick={onClickTimeWindow(SEVENDAYS)}>7 Days</TimeWindowButton>
            </TimeWindowContainer>
            {errorQueue && holdQueue && (
                <Chart
                    width={"100%"}
                    height={"470px"}
                    type={"line"}
                    series={series}
                    options={options}
                />
            )}
        </ContentContainer>
    );
};

export default QueueSizeChart;
