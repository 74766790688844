import React from "react";
import IRhapsodyCertificate from "src/ServerEntities/IRhapsodyCertificate";
import IRhapsodyServer from "src/ServerEntities/IRhapsodyServer";
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from "src/SharedComponents/LoadingIndicator";
import { SessionContext } from "src/Views/SessionContext";
import { getRhapsodyCertificates, getRhapsodyHeader } from "../ramService";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
  border: 4px solid #44A2D6;
  height: 100%;
  width: 80%;
  box-sizing: border-box;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
`;

const TableHeader = styled.thead`
`;

const TableHeaderCell = styled.th`
  padding: 12px 16px;
  text-align: left;
  font-weight: 600;
  color: #1577AE;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  padding: 6px 16px;
  color: #212529;
  font-weight: 600;
`;

const TieInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
`;

const TieTitle = styled.div`
    font-size: 1.3em;
    font-weight: bold;
`;

const TitleBar = styled.div`
    margin: 8px;
    border-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    box-sizing: border-box;
`;

const StatusBadge = styled.span<{ $status: 'Expired' | 'Expires within 1 month' | 'Active' }>`
    padding: 4px 16px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 600;
    color: black;
    
    
    ${props => {
        switch (props.$status) {
            case 'Expired':
                return `
                    background-color: #FF2121;
                    color: #ffffff;
                `;
            case 'Expires within 1 month':
                return `
                    background-color: #2DF6C0;
                `;
            case 'Active':
                return `
                    background-color: #5CEA5A;
                `;
        }
    }}
`;

const RhapsodyCertificates = () => {
    const { state, dispatch } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [data, setData] = React.useState<IRhapsodyServer[]>([]);
    const [certificates, setCertificates] = React.useState<IRhapsodyCertificate[]>([]);
    const headerInfo = state.headerInfo ? state.headerInfo : data;

    const getExpiryStatus = (expiryDate: number) => {
        const now = Date.now();
        const oneMonth = 30 * 24 * 60 * 60 * 1000;
        
        if (expiryDate < now) {
            return 'Expired';
        } else if (expiryDate < now + oneMonth) {
            return 'Expires within 1 month';
        }
        return 'Active';
    };
 

    React.useEffect(() => {
        if (!state.headerInfo) {
            setLoading(true);
            getRhapsodyHeader(state.webToken, (serverData: IRhapsodyServer[]) => {
                setLoading(false);
                setData(serverData);
                setError("");
                loadServers();
                dispatch({ type: "setHeaderInfo", payload: serverData });
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
        else {
            loadServers();
        }
    }, []);

    const loadServers = () => {
        setLoading(true);
        getRhapsodyCertificates(state.webToken, (serverData: IRhapsodyCertificate[]) => {
          setLoading(false);
          setCertificates(serverData);
        }, (erroMessage: string) => {
          setLoading(false);
          setError(erroMessage);
        });
    };

    const formatDate = (timestamp: number) => {
        return new Date(timestamp).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    return (
        <ContentContainer>
            <LoadingIndicator type="Linear" show={loading} />
            {error && <ErrorBox>{error}</ErrorBox>}
            {headerInfo && <TieInfo>
                <TieTitle>Rhapsody Integration Engine</TieTitle>
            </TieInfo>}
            <TableContainer>
                <TitleBar>SSL Certificates</TitleBar>
                <StyledTable>
                    <TableHeader>
                            <TableHeaderCell>Alias<FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px", paddingBottom: "2px", justifyContent: "center" }} /></TableHeaderCell>
                            <TableHeaderCell>Type<FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px", paddingBottom: "2px", justifyContent: "center" }} /></TableHeaderCell>
                            <TableHeaderCell>Expiry<FontAwesomeIcon icon={faSortDown} style={{ marginLeft: "8px", paddingBottom: "2px", justifyContent: "center" }} /></TableHeaderCell>
                            <TableHeaderCell></TableHeaderCell>
                    </TableHeader>
                    <tbody>
                        {certificates && certificates.map((cert) => {
                            const status = getExpiryStatus(cert.expiry);
                            
                            return (
                                <TableRow key={cert.id}>
                                    <TableCell>{cert.alias}</TableCell>
                                    <TableCell>{cert.type}</TableCell>
                                    <TableCell>{formatDate(cert.expiry)}</TableCell>
                                    <TableCell style={{display: "flex", justifyContent: "flex-end"}}>
                                            <StatusBadge $status={status}>
                                                {status}
                                            </StatusBadge>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </tbody>
                </StyledTable> 
            </TableContainer>
        </ContentContainer>
    );
};

export default RhapsodyCertificates;

