import React from 'react';
import 'react-toggle/style.css';
import styled from 'styled-components';

import IRhapsodyServer from 'src/ServerEntities/IRhapsodyServer';
import IRhapsodyServerPanel from 'src/ServerEntities/IRhapsodyServerPanel';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import { SessionContext } from 'src/Views/SessionContext';
import { getRhapsodyHeader, getRhapsodyServers } from '../ramService';
import { ContentContainer } from './Dashboard';
import ServerDetailsPanel from './ServeDetailsPanel';


const SiteContainer = styled.ul`
    margin-top: 24px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1280px;
        margin-top: 24px;
    flex: 1 1 auto;
    display: flex;
`;

interface IProps {
    selectedTypes: string[],
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};

const TieInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
`;

const TieTitle = styled.div`
    font-size: 25px;
    font-weight: bold;
`;

const VersionTitle = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #1577AE;
`;

const LicenceTitle = styled.div`
    display: flex;
    flex-direction: column;
     font-size: 18px;
    font-weight: bold;
`;

const LicenceItem = styled.div`
`;

const ServerContainer = styled.div`
 display: flex;
    flex-direction: row;
`;

const ServerItem = styled.div`
    border-radius: 30px;
    margin-right: 20px;
    padding: 8px;
    background-color: #ccc;
    color: #FFF;
    width: 300px;
    text-align: center;
    font-size: 24px;
`;

const ServerItemSelected = styled.div`
    border-radius: 30px;
    margin-right: 20px;
    padding: 8px;
    background-color: #1577AE;
    color: #FFF;
    width: 300px;
    text-align: center;
    font-size: 24px;
`;

const RhapsodyServers = (props: IProps) => {
    const { state, dispatch } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [data, setData] = React.useState(undefined as unknown as IRhapsodyServer[]);
    const [selectedServer, setSelectedServer] = React.useState(0);
    const [serverPanels, setServerPanels] = React.useState(undefined as unknown as IRhapsodyServerPanel[])
    const headerInfo = state.headerInfo ? state.headerInfo : data;

    React.useEffect(() => {
        if (!state.headerInfo) {
            setLoading(true);
            getRhapsodyHeader(state.webToken, (serverData: IRhapsodyServer[]) => {
                setLoading(false);
                setData(serverData);
                setError("");
                loadServers();
                dispatch({ type: "setHeaderInfo", payload: serverData });
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
        else {
            loadServers();
        }
    }, []);

    const loadServers = () => {
        setLoading(true);
        getRhapsodyServers(state.webToken, (data: IRhapsodyServerPanel[]) => {
            setLoading(false);
            setServerPanels(data);
            setError("");
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const serverToTab = (server: IRhapsodyServer, index: number) => {

        const selectServer = (serverIndex: number) => () => {
            setSelectedServer(serverIndex);
        }

        if (selectedServer === index) {
            return <ServerItemSelected key={`rhapsody-server-${server.id}-details`} onClick={selectServer(index)}>{server.server}</ServerItemSelected>
        }
        return <ServerItem key={`rhapsody-server-${server.id}-details`} onClick={selectServer(index)}>{server.server}</ServerItem>
    }


    return <ContentContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        {headerInfo && headerInfo[selectedServer] && <TieInfo>
            <TieTitle>Rhapsody Integration Engine</TieTitle>
            <VersionTitle>version {headerInfo[selectedServer].licenceVersion}</VersionTitle>
            <LicenceTitle>
                <LicenceItem>Licence: {headerInfo[selectedServer].licenceEndUser}</LicenceItem>
                <LicenceItem>Expiry: {dateConverter(headerInfo[selectedServer].licenceExpiryDate)}</LicenceItem>
            </LicenceTitle>
        </TieInfo>}
        <ServerContainer>
            {headerInfo && headerInfo.map(serverToTab)}
        </ServerContainer>
        <SiteContainer>
            {serverPanels && serverPanels.map((serverPanel: IRhapsodyServerPanel, index: number) => {
                return <ServerDetailsPanel key={`rhapsody-server-${index}-panel`} data={serverPanel} />
            })}
        </SiteContainer>
    </ContentContainer>;
};

export default RhapsodyServers;
