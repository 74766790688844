import Timeago from 'react-timeago';
import IServer from 'src/ServerEntities/IServer';
import styled from 'styled-components';
import DiskUsage from './DiskUsage';

const ServerHistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #1c3e5a;
    min-width: 150px;
    & h3 {
        background-color: #246896;
        margin: 0;
        flex: 0 0 auto;
        padding: 12px 12px 6px;
        @media (min-width: 1279px) {
            border-radius: 16px 16px 0 0;
        }
        font-weight: 500;
        font-size: 1.2rem;
    }
    & dl {
        flex: 0 0 auto;
        padding: 12px;
        font-size: 0.8rem;
    }
    @media (min-width: 1279px) {
        flex: 1 1 auto;
        border-radius: 16px;
    }
    @media (max-width: 1279px) {
        width: 100%;
        margin-bottom: 4px;
    }
`;

interface IProps {
    server: IServer
};

const formatDiskFreeSpace = (value: string) => {
    if (!value.includes("%")) {
        return value + "%";
    }
    return value;
};

const ServerHistory = (props: IProps) => {
    const { server } = props;
    return <ServerHistoryContainer>
        <h3>Server history</h3>
        <dl>
            <dt>Last heartbeat:</dt><dd><Timeago date={server.lastHeartbeat} /></dd>
            {server.backup && [
                <dt key={`server-history-${server.name}-last-backup-key`}>Last backup:</dt>,
                <dd key={`server-history-${server.name}-last-backup-value`}><Timeago date={server.backup.date} /></dd>
            ]}
            {server.database && [
                <dt key={`server-history-${server.name}-db-size-key`}>Database size:</dt>,
                <dd key={`server-history-${server.name}-db-size-value`}>{server.database.databaseSize}</dd>,
                <dt key={`server-history-${server.name}-db-disk-free-key`}>Disk free space:</dt>,
                <dd key={`server-history-${server.name}-db-disk-free-value`}>
                    {server.database.availableSize} {formatDiskFreeSpace(server.database.databaseFreeSpace)}
                </dd>
            ]}
            {server.disks.map((disk) => <DiskUsage diskUsage={disk} />)}
            {server.uptime && server.uptime.lastOsBoot && [
                <dt key={`server-history-${server.name}-last-os-boot-key`}>Last OS boot:</dt>,
                <dd key={`server-history-${server.name}-last-os-boot-value`}><Timeago date={server.uptime.lastOsBoot} /></dd>
            ]}
        </dl>
    </ServerHistoryContainer>;
};

export default ServerHistory;